<template>
    <!-- <vx-card title="SKU View"> -->
        <div>
            <div class="vx-row ml-1 mt-3">
                <div class="vx-row mb-6" style="width:70%">
                    <div class="vx-col sm:w-1/4 w-full flex items-center">
                        <span>Supplier Code</span>
                    </div>
                    <div class="vx-col sm:w-3/4 w-full">
                        <CustomSelectSupplier :value="selectedSrcSuppliers" base-url="/api/wms/v1/master/suppliers" 
                            :custom-label="customSupplier" 
                            track-by="name" 
                            :multiple="true" 
                            :allowEmpty="false"
                            @selected="(val) => {
                                this.selectedSrcSuppliers = val;
                            }">
                        </CustomSelectSupplier>
                    </div>
                </div>
                <div class="vx-row mb-6" style="width:70%">
                    <div class="vx-col sm:w-1/4 w-full flex items-center">
                        <span>Warehouse Code</span>
                    </div>
                    <div class="vx-col sm:w-3/4 w-full">
                        <CustomSelect :value="selectedSrcWarehouses" base-url="/api/wms/v1/master/warehouses" 
                            label="name" 
                            track-by="name" 
                            :multiple="true" 
                            :allowEmpty="false"
                            @selected="(val) => {
                                this.selectedSrcWarehouses = val;
                            }">
                        </CustomSelect>
                    </div>
                </div>
                <div class="vx-row mb-6" style="width:70%">
                    <div class="vx-col sm:w-1/4 w-full flex items-center">
                        <span>SKU Code</span>
                    </div>
                    <div class="vx-col sm:w-3/4 w-full">
                        <CustomSelectItems ref="skuComponent" :value="selectedSrcItems" :base-url="generateItemApiUrl()" 
                            :custom-label="customSku" 
                            track-by="id" 
                            :multiple="true" 
                            :allowEmpty="false"
                            @selected="(val) => {
                                this.selectedSrcItems = val;
                            }">
                        </CustomSelectItems>
                    </div>
                </div>
                <div class="vx-row mb-6" style="width:70%">
                    <div class="vx-col sm:w-1/4 w-full flex items-center">
                        <span>HU</span>
                    </div>
                    <div class="vx-col sm:w-3/4 w-full">
                        <CustomSelect ref="huComponent" :value="selectedSrcItemUnits" :base-url="generateItemUnitApiUrl()" 
                            label="name" 
                            track-by="name" 
                            :multiple="true" 
                            :allowEmpty="false"
                            @selected="(val) => {
                                this.selectedSrcItemUnits = val;
                            }">
                        </CustomSelect>
                    </div>
                </div>
                <div class="vx-row mb-6" style="width:70%">
                    <div class="vx-col sm:w-1/4 w-full flex items-center">
                        <span>Status</span>
                    </div>
                    <div class="vx-col sm:w-3/4 w-full">
                        <CustomSelect1 :value="selectedStatus" 
                            label="code" 
                            track-by="name" 
                            :multiple="true" 
                            :allowEmpty="false"
                            :options="statusOptions"
                            @selected="(val) => {
                                this.selectedStatus = val;
                            }">
                        </CustomSelect1>
                    </div>
                </div>
                <div class="vx-row mb-6" style="width:70%">
                    <div class="vx-col sm:w-1/4 w-full flex items-center">
                    </div>
                    <div class="vx-col sm:w-3/4 w-full">
                        <vs-button color="primary" @click="handleFilter()">Filter</vs-button>
                        <!-- <vs-button class="ml-5" color="primary" @click="handleExport()">Export</vs-button> -->
                    </div>
                </div>
            </div>
            <vs-tabs :value="active">
                <vs-tab label="Price">
                    <TablePrice
                        :draw="draw"
                        :skuCodes="this.selectedItemCode"
                        :unitNames="this.selectedItemUnitIDs"
                        :whIDs="this.selectedWarehouseIDs"
                        :supplierIDs="this.selectedSupplierIDs"
                        :source-status="this.statusses">
                    </TablePrice>
                </vs-tab>
                <vs-tab label="Discount">
                    <div class="tab-text">
                    <TableDiscount
                        :draw="draw"
                        :skuCodes="this.selectedItemCode"
                        :unitNames="this.selectedItemUnitIDs"
                        :whIDs="this.selectedWarehouseIDs"
                        :supplierIDs="this.selectedSupplierIDs"
                        :source-status="this.statusses">
                    </TableDiscount>
                    </div>
                </vs-tab>
            </vs-tabs>
        </div>
    <!-- </vx-card> -->
</template>
<script>

import Warehouse from "@/components/Warehouse.vue";
import Supplier from "@/components/Supplier.vue";
import Item from "./component/Item.vue";
import ItemUnit from "./component/ItemUnit.vue";
import CustomSelect from "./component/CustomSelect.vue";
import CustomSelect1 from "./component/CustomSelect1.vue";
import CustomSelectItems from "./component/CustomSelectItems.vue";
import CustomSelectSupplier from "./component/CustomSelectSupplier.vue";
import TablePrice from "./component/table.vue";
import TableDiscount from "./component/tableDiscount.vue";
import moment from "moment";
import vSelect from "vue-select";
export default {
    components: {
        Warehouse,
        Supplier,
        Item,
        ItemUnit,
        // Date,
        CustomSelect,
        CustomSelect1,
        CustomSelectItems,
        CustomSelectSupplier,
        TablePrice,
        TableDiscount,
        vSelect
    },
    mounted() {
        let a = {
            id: 0,
            code: "All",
            name: "all"
        }

        let b = {
            code: "All",
            name: "all"
        }

        let c = {
            id: 0,
            sku_code: "all",
            name: ""
        }

        let d = {
            id: 0,
            code: "All",
            name: ""
        }
        if (this.selectedSrcSuppliers.length == 0) {
            this.selectedSrcSuppliers = [d]
        }

        if (this.selectedSrcWarehouses.length == 0) {
            this.selectedSrcWarehouses = [a]
        }

        if (this.selectedSrcItems.length == 0) {
            this.selectedSrcItems = [c]
        }

        if (this.selectedSrcItemUnits.length == 0) {
            this.selectedSrcItemUnits = [a]
        }

        if (this.selectedStatus.length == 0) {
            this.selectedStatus = [b]
        }
    },
    data() {
        return {
            activeTab: "Received",
            draw: 0,
            selectedSrcItems: [],
            selectedSrcItemUnits: [],
            selectedSrcWarehouses: [],
            selectedSrcSuppliers: [],
            selectedStatus: [],
            selectedSupplierIDs: "0",
            selectedWarehouseIDs: "0",
            selectedItemIDs: "0",
            selectedItemCode: "",
            selectedItemUnitIDs: "0",
            skuCodes: "",
            unitNames: "",
            supplierIDs: "",
            whIDs: "",
            statusses: "",
            statusOptions: [
                { code: 'All', name: 'all' },
                { code: 'Draft', name: 'new' },
                { code: 'Released', name: 'released' },
                { code: 'Amended', name: 'amended' },
                { code: 'Expired', name: 'expired' },
                { code: 'Canceled', name: 'canceled' }
            ]
        }
    },
    methods: {
        customSku({ sku_code, name }) {
            return `${sku_code}  ${name}`;
        },
        customSupplier({ code, name }) {
            return `${code}  ${name}`;
        },
        handleFilter() {
            this.draw++;
        },
        generateItemApiUrl() {
            const supplierId = this.selectedSupplierIDs;
            console.log('Supplier',supplierId)
            return `/api/wms/v1/master/items/multi-supplier?supplier_ids=${supplierId}`;
        },
        generateItemUnitApiUrl() {
            const itemId = this.selectedItemIDs;
            console.log('Item',itemId)
            return `/api/wms/v1/master/item-unit/multi-item?item_ids=${itemId}`;
        },
        handleExport() {
            this.$vs.loading();
            this.$http
            .post(this.$store.state.master.skuView.baseUrlPath + "/export", {
                file_name: "SKU-View-Buying-Price-" + moment().format('YYYY-MM-DD'),
                warehouse_ids: this.selectedWarehouseIDs,
                supplier_ids: this.selectedSupplierIDs,
                sku_codes: this.selectedItemCode,
                unit_names: this.selectedItemUnitIDs,
                status: this.statusses,
                type: "sku-view-buying-price"
            })
            .then((resp) => {
                if (resp.code == 200) {
                this.$vs.notify({
                    color: "success",
                    title: "Success",
                    text: resp.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                this.$vs.loading.close();
                } else {
                this.$vs.notify({
                    color: "danger",
                    title: "Form Validation",
                    text: resp.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                this.$vs.loading.close();
                }
            });
        },
    },
    watch: {
        selectedSrcSuppliers(v) {
            var supplierIds = []
            if (Array.isArray(v)) {
                v.forEach(e => { if (e.id) supplierIds.push(e.id) });
            }
            this.selectedSupplierIDs = supplierIds.join(",");
            this.$nextTick(() => {
                this.$refs.skuComponent.get();
            });
        },
        selectedSrcWarehouses(v) {
            var whIds = []
            if (Array.isArray(v)) {
                v.forEach(e => { if (e.id) whIds.push(e.id) });
            }
            this.selectedWarehouseIDs = whIds.join(",")
        },
        selectedSrcItems(v) {
            var skuIds = []
            var skuCodes = []
            if (Array.isArray(v)) {
                v.forEach(e => { if (e.id) skuIds.push(e.id),skuCodes.push(e.sku_code) });
            }
            this.selectedItemIDs = skuIds.join(",")
            this.selectedItemCode = skuCodes.join(",")
            this.$nextTick(() => {
                this.$refs.huComponent.get();
            });
        },
        selectedSrcItemUnits(v) {
            console.log('hasil sku',v)
            var unitIds = []
            if (Array.isArray(v)) {
                v.forEach(e => { if (e.name) unitIds.push(e.name) });
            }
            this.selectedItemUnitIDs = unitIds.join(",")
        },
        selectedStatus(v) {
            var valueStatus = []
            if (Array.isArray(v)) {
                v.forEach(e => { if (e.name) valueStatus.push(e.name) });
            }
            this.statusses = valueStatus.join(",")
        }
        // "selectedStatus": function(val) {
        //     console.log("statusses ", val)
        //     let statusAll = false
        //     val.map(function(x) {
        //         if (x.value == 'all') {
        //             statusAll = true
        //         }
        //     })

        //     this.selectedStatus = []
        //     if (statusAll) {

        //     } else {
        //         let statusses = []
        //         val.map(function(v) {
        //             statusses.push(v.value) 
        //         })
        //         if (statusses.length == 0) {
        //             this.statusses = 'all'
        //         } else {
        //             this.statusses = statusses.join(",")
        //         }
        //     }
        // }
    }
}
</script>
