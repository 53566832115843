<template>
    <div>
        <vs-tabs :value="active">
            <vs-tab label="Price">
                <Table
                    :draw="draw">
                </Table>
            </vs-tab>
            <vs-tab label="Discount">
                <div class="tab-text">
                <TableDiscount
                    :draw="draw">
                </TableDiscount>
                </div>
            </vs-tab>
        </vs-tabs>
    </div>
</template>
<script>

import Table from "./component/tableExport.vue";
import TableDiscount from "./component/tableExportDiscount.vue";
export default {
    components: {
        Table,
        TableDiscount
    },
    data() {
        return {
            
        }
    },
    methods: {
        handleFilter() {
            this.draw++;
        },
    },
    watch: {

    }
}
</script>
