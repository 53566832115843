<template>
    <div>
        <div class="vx-row mb-2 mt-2" style="width:70%">
            <div class="vx-col sm:w-1/4 w-full">
                <vs-button color="primary" @click="handleExport()">Export</vs-button>
            </div>
        </div>
        <vs-table search stripe border description :sst="true" :data="table.data" :max-items="table.length"
            :total="table.total" @search="handleSearch" @change-page="handleChangePage" @sort="handleSort">
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div
                        class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of
                            {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </template>
            <template slot="thead">
                <vs-th sort-key="">Discount Code</vs-th>
                <vs-th sort-key="">Discount Name</vs-th>
                <vs-th sort-key="">Valid From - Valid To</vs-th>
                <vs-th sort-key="">Currency Code</vs-th>
                <vs-th sort-key="">Supplier</vs-th>
                <vs-th sort-key="">Warehouse</vs-th>
                <vs-th sort-key="">Status</vs-th>
                <vs-th sort-key="">Date Amend</vs-th>
                <vs-th sort-key="">Source</vs-th>
                <vs-th sort-key="">Sku Code - Name</vs-th>
                <vs-th sort-key="">Sku Category Name</vs-th>
                <vs-th sort-key="">Discount Model</vs-th>
                <vs-th sort-key="">Calculation Model</vs-th>
                <vs-th sort-key="">Level</vs-th>
                <vs-th sort-key="">Discount Type</vs-th>
                <vs-th sort-key="">Base Calculation</vs-th>
                <vs-th sort-key="">MAP</vs-th>
                <vs-th sort-key="">From Qty</vs-th>
                <vs-th sort-key="">To Qty</vs-th>
                <vs-th sort-key="">HU</vs-th>
                <vs-th sort-key="">Discount</vs-th>
                <vs-th sort-key="">Unit</vs-th>
                <vs-th sort-key="">Amount</vs-th>
                <vs-th sort-key="">Multiply</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>{{  tr.discount_code }}</vs-td>
                    <vs-td>{{  tr.discount_name }}</vs-td>
                    <vs-td>{{ formatDate(tr.valid_from) }} - {{ formatDate(tr.valid_to) }}</vs-td>
                    <vs-td>{{  tr.currency_code }}</vs-td>
                    <vs-td>{{ tr.supplier_code }} - {{ tr.supplier_name }}</vs-td>
                    <vs-td>{{ tr.warehouse }}</vs-td>
                    <vs-td>{{ tr.status }}</vs-td>
                    <vs-td>{{ tr.date_amend ? formatDate(tr.date_amend) : '' }}</vs-td>
                    <vs-td>{{ tr.source }}</vs-td>
                    <vs-td>{{ tr.sku_code }} - {{ tr.sku_name }}</vs-td>
                    <vs-td>{{ tr.sku_category_name }}</vs-td>
                    <vs-td>{{ tr.discount_model }}</vs-td>
                    <vs-td>{{ tr.calculation_model }}</vs-td>
                    <vs-td>{{ tr.level }}</vs-td>
                    <vs-td>{{ tr.discount_type }}</vs-td>
                    <vs-td>{{ tr.base_calculation }}</vs-td>
                    <vs-td>{{ tr.map }}</vs-td>
                    <vs-td>{{ tr.from_qty }}</vs-td>
                    <vs-td>{{ tr.to_qty }}</vs-td>
                    <vs-td>{{ tr.hu }}</vs-td>
                    <vs-td>{{ tr.discount }}</vs-td>
                    <vs-td>{{ tr.unit }}</vs-td>
                    <vs-td>{{ tr.amount }}</vs-td>
                    <vs-td>{{ tr.multiply }}</vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
    </div>
</template>
<script>
import moment from "moment";
export default {
    components: {},
    props: {
        draw: {
            type: Number,
        },
        // sourceWarehouseId: {
        //     type: Number,
        // },
        // sourceItemCode: {
        //     type: String,
        // },
        // sourceUnitName: {
        //     type: String,
        // },
        // sourceSupplierId: {
        //     type: Number,
        // },
        skuCodes: {
            type: String,
        },
        unitNames: {
            type: String,
        },
        whIDs: {
            type: String,
        },
        supplierIDs: {
            type: String,
        },
        sourceStatus: {
            type: String,
        },
    },
    data() {
        return {
            confirmId: null,
            table: this.tableDefaultState(),
        };
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            this.getData();
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        handleClose() {
            this.getData();
            this.$emit("close");
        },
        // handleCreate() {
        //     this.customerId = null;
        //     this.detail = true;
        // },
        getData() {
            this.$vs.loading();
            const params = {
                length: this.table.length,
                page: this.table.page,
                search: this.table.search,
                order: this.table.order,
                sort: this.table.sort,
                source_warehouse_ids: this.whIDs,
                source_supplier_ids: this.supplierIDs,
                source_item_codes: this.skuCodes,
                source_unit_names: this.unitNames,
                source_status: this.sourceStatus,
                // transaction_date: (this.transactionDate) ? moment(this.transactionDate).format("YYYY-MM-DD") : null,
            };

            this.$http
                .get(this.$store.state.master.skuView.baseUrlPath + '-discount', { params })
                .then((resp) => {
                    if (resp.code == 200) {
                        console.log("data supplier price",resp.data.records)
                        this.table.total = resp.data.total_record;
                        this.table.totalPage = resp.data.total_page;
                        this.table.totalSearch = resp.data.total_record_search;
                        this.table.length = resp.data.total_record_per_page;
                        this.table.data = resp.data.records;
                        this.setStartEnd();
                        this.$vs.loading.close();
                    } else {
                        // Handle the case when resp.code is not 200
                        // this.$vs.loading.close();
                    }
                });
        },
        setStartEnd() {
            let valStart =
                this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (
                this.table.totalSearch < this.table.total &&
                this.table.search != ""
            ) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        utcToLocal(val) {
            if (val) {
                return moment(val)
                    .add(-7, "h")
                    .utc()
                    .local()
                    .format("DD MMM YYYY HH:mm:ss");
            }
        },
        formatDate(dateString) {
            // Ubah string tanggal menjadi objek Date
            let date = new Date(dateString);
            // Format tanggal sesuai kebutuhan ("YYYY-MM-DD")
            let formattedDate = date.getFullYear() + '-' + (date.getMonth() + 1).toString().padStart(2, '0') + '-' + date.getDate().toString().padStart(2, '0');
            return formattedDate;
        },
        getCurrentDate() {
            let today = new Date();
            let formattedDate = today.getFullYear() + '-' + (today.getMonth() + 1).toString().padStart(2, '0') + '-' + today.getDate().toString().padStart(2, '0');
            return formattedDate;
        },
        handleExport() {
            this.$vs.loading();
            this.$http
            .post(this.$store.state.master.skuView.baseUrlPath + "/export-discount", {
                file_name: "SKU-View-Purchase-Discount-" + moment().format('YYYY-MM-DD'),
                warehouse_ids: this.whIDs,
                supplier_ids: this.supplierIDs,
                sku_codes: this.skuCodes,
                unit_names: this.unitNames,
                status: this.sourceStatus,
                type: "sku-view-purchase-discount"
            })
            .then((resp) => {
                if (resp.code == 200) {
                this.$vs.notify({
                    color: "success",
                    title: "Success",
                    text: resp.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                this.$vs.loading.close();
                } else {
                this.$vs.notify({
                    color: "danger",
                    title: "Form Validation",
                    text: resp.message,
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-x-circle",
                });
                this.$vs.loading.close();
                }
            });
        },
    },
    mounted() { },
    watch: {
        // sourceWarehouseId() {
        //     this.getData();
        // },
        // transactionDate() {
        //     this.getData();
        // },
        // status() {
        //     this.getData();
        // },
        draw() {
            this.getData();
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
    },
};
</script>
